.zen-loading-spinner {
  animation: rotating 1s linear infinite;
  background: transparent;
  border: 2px solid;
  border-bottom-color: transparent !important;
  border-radius: 100%;
  display: inline-block;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
